import React from 'react'
import Layout from '../components/Layout'
import Content from '../components/Content'
import PageHero from '../components/PageHero'
import {Link} from 'gatsby'

const assets = {
  omn_user_girl: require('../resources/img/omn_user_girl.jpg'),
  merchant_1: require('../resources/img/merchant_store_fashion_1.jpg'),
  woman_1: require('../resources/img/woman_1.jpg'),
}

export default () => (
  <Layout page="why">
    <div className="clearfix">
      <PageHero title="Pourquoi choisir Vitepay ?" content={
        'Notre mission est d\'aider les entreprises à adopter les paiements mobiles sur Internet tout en protégeant les consommateurs.'
      }/>
    </div>

    <div className="oblique-green container-fluid  clearfix" style={{ padding: '0 220px' }}>
      <Content
        image={assets.omn_user_girl}
        title={'Le paiement mobile: un réflexe'}
        content={
          <div>
            <p>
              Internet est un vecteur de croissance qu'il n'est plus possible d'ignorer pour une entreprise, quelle
              qu'en
              soit la taille.
              Avoir un canal de vente sur internet permet de toucher plus de clients et d'augmenter son d'affaire.
            </p>
            <p>
              Face à cette opportunité, les sites marchands doivent disposer d'un moyen de paiement sécurisé et
              populaire
              à présenter aux clients.<br/>
            </p>
            <p>
              Ce moyen de paiement existe déjà est c'est le <strong>paiement mobile</strong>.
            </p>
            <p>
              L'objectif de Vitepay est de démocratiser l'usage des paiements mobiles sur les sites et
              applications
              e-commerce au Mali.
            </p>

          </div>
        }
      />
    </div>
    <div className="container-fluid circle-bg-grey clearfix" style={{ padding: '0 220px' }}>
      <Content flip image={assets.merchant_1}
               title={'Un levier pour les marchands'}
               paddingTop={80}
               content={
                 <div>
                   <div className="cleafix mt">
                     <p>
                       Quelle que soit votre catalogue de produits et services
                       Vitepay peut vous aider à développer votre activité en ligne et recevoir des paiments mobiles.
                     </p>
                     <p>
                       De la construction de votre boutique en ligne, l'intégration du paiement à la livraison, les
                       produits Vitepay sont pensés et conçus pour servir de levier de croissance aux entreprises
                       maliennes.
                     </p>
                     <p>

                     </p>
                   </div>
                   <div className="clearfix mt-5">
                     <Link to="/signup">Découvrez nos produits</Link>
                   </div>
                 </div>
               }
      />
    </div>
    <div className="oblique-blue-rev container-fluid  clearfix" style={{ padding: '0 220px' }}>
      <Content
        image={assets.woman_1}
        title={'Protection des consommateurs'}
        content={
          <div>
            <p>
              L'un des principaux freins au développement et l'adoption de l'e-commerce est la confiance des
              consommateurs:
              arnaques en ligne en tout genre, mauvaise fois, politique confuse de remboursement, livraisons chers ou
              peu fiables, etc.
            </p>
            <p className="lead">
              Vitepay sécurise vos tous vos paiements paiements mobiles. Une fois votre achat validé, nous ne créditons
              le compte marchand
              que 7 jours après pour permettre le règlement des litiges éventuels à l'amiable. <br/>
              Nous mettons également en place des partenariats pour simplifier les livraisons et nous assurez que vous
              êtes livré.e et satisfait.e.
            </p>
            <div className="clearfix mt-5">
              <Link to="/signup">En avoir plus sur vos droits</Link>
            </div>
          </div>
        }
      />
    </div>
  </Layout>
)
